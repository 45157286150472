<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { serviceClassification } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/serviceProject/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          initUrl: '/clientUser/serviceProject/add/get?id=' + this.$route.query.id,
          controls: [
            {
              key: 'serviceName',
              label: '服务项目',
              type: 'text'
            },
            {
              key: 'serviceClassification',
              label: '分类',
              type: 'select',
              config: {
                options: 'serviceType'
              }
            },
            {},
            {}
          ]
        },
        columns: [
          {
            key: 'serviceName',
            title: '服务项目',
            width: '100px',
          },
          {
            key: 'serviceClassification',
            title: '分类',
            width: '100px',
            type: 'textEnum',
            config: {
              constants: {
                'HIGH_END_DIRECT_RECORDING': '留学保录',
                'APPLICATION_FOR_STUDYING_ABROAD': '常规申请',
                'ART_APPLY': '艺术申请',
                'DOC_SERVICE': '文书服务',
                'COURSE_TRAINING': '语言考试',
                'IMMIGRANT': '移民',
                'BACKGROUND_BOOST': '背景提升',
                'INTERNATIONAL_SCHOOL': '国际学校',
                'CH_EN_COOPERATE': '中外合办',
                'TUTORING': '课业辅导',
                'TO_B': 'To B',
                'ABROAD_PREMASTER': '海外预科',
                'FAMOUS_ENTERPRISE_PRACTICE': '名企实习',
                'LOW_AGE_CHOOSE_SCHOOL': '低龄择校'
              }
            }
          },
          {
            key: 'countryOfApplication',
            title: '申请国家/地区',
            width: '100px',
          },
          {
            key: 'minPrice',
            title: '起步价',
            width: '100px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'briefIntroduction',
            title: '简介',
            width: '300px',
            ellipsis: true
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建服务项目',
            type: 'form',
            permission: '/clientUser/serviceProject/insert',
            config: {
              color: 'primary',
              title: '新建服务项目',
              initUrl: '/clientUser/serviceProject/add/get?id=' + this.$route.query.id,
              submitUrl: "/clientUser/serviceProject/insert",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'serviceName',
                  label: '服务项目',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'serviceClassification',
                  label: '服务分类',
                  type: 'select',
                  required: true,
                  config: {
                    options: 'serviceType'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'minPrice',
                  width: '130px',
                  label: '起步价(人民币)',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'unit',
                    text: '元'
                  }
                },
                {
                  key: 'countryOfApplication',
                  label: '申请国家/地区',
                  type: 'mSelect',
                  config: {
                    options: 'country'
                  }
                },
                {
                  key: 'serviceHighlights',
                  label: '服务亮点',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 15, message: '请尽量精简内容，输入15字以内' }
                    ]
                  }
                },
                {
                  key: 'applyTeacher',
                  label: '申请老师',
                  limitNum: 3,
                  type: 'mSelect',
                  config: {
                    options: 'applyTeacher'
                  }
                },
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议上传像素为 680x373 ；不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'textarea',
                  required: true,
                  config: {
                  }
                },
                {
                  key: 'detail',
                  label: '服务详情',
                  tips: "可以添加组合多段图片，App上将按顺序展示为一整套图文信息。图片推荐宽度750px，单张图片请控制在5M以内。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/serviceProject/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*1000*9,
                    length: 9,
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/serviceProject/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条服务项目，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/serviceProject/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/serviceProject/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/serviceProject/get",
              submitUrl: "/clientUser/serviceProject/update",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'serviceName',
                  label: '服务项目',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'serviceClassification',
                  label: '服务分类',
                  type: 'select',
                  required: true,
                  config: {
                    options: 'serviceType'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'minPrice',
                  width: '130px',
                  label: '起步价(人民币)',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'unit',
                    text: '元'
                  }
                },
                {
                  key: 'countryOfApplication',
                  label: '申请国家/地区',
                  type: 'mSelect',
                  config: {
                    options: 'country'
                  }
                },
                {
                  key: 'serviceHighlights',
                  label: '服务亮点',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 15, message: '请尽量精简内容，输入15字以内' }
                    ]
                  }
                },
                {
                  key: 'applyTeacher',
                  label: '申请老师',
                  limitNum: 3,
                  type: 'mSelect',
                  config: {
                    options: 'applyTeacher'
                  }
                },
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议上传像素为 680x373 ；不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'textarea',
                  required: true,
                  config: {
                  }
                },
                {
                  key: 'detail',
                  label: '服务详情',
                  tips: "可以添加组合多段图片，App上将按顺序展示为一整套图文信息。图片推荐宽度750px，单张图片请控制在5M以内。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/serviceProject/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*1000*9,
                    length: 9,
                  }
                },
                {}
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>